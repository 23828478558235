<template>
  <div class="home">
    <div class="home-product grace-space-between">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="10"
      >
        <van-cell class="items" v-for="(item,index) in auctionList" :key="index" @click="imgpPreview(item)">
          <img mode="widthFix" :src="item.image"/>
          <div class="item-text" v-if="false">
            <h5>{{item.title}}</h5>
            <div class="flex-start">
                <strong>{{item.create_time}}</strong>
            </div>
          </div>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>

import {
  journalismList
} from "@/api/index";
import { ImagePreview } from 'vant';

export default {
  components: {
  },
  data(){
    return{
      auctionList:[],
      loading:false,
      finished:false,
      page: 1,//请求第几页
      pageSize: 10,//每页请求的数量
      total:0,
    }
  },
  created(){
    this.initTable();
  },
  methods:{
    loadmore(){

    },
    onLoad() {
      this.page++;
      this.initTable();
    },
    imgpPreview(row){
      ImagePreview([row.image]);
    },
    initTable(){
      let datas = {
        page:this.page,
        type:1,
        categoryId:Number(this.$route.query.id)
      }
      journalismList(datas).then(res=>{
        this.tableFilter = res.data.data;
        this.total = res.data.newsList.total;
        this.loading = false;
        if (res.data.newsList.list == null || res.data.newsList.list.length === 0) {
          // 加载结束
          this.finished = true;
          return;
        }

        // 将新数据与老数据进行合并
        this.auctionList = this.auctionList.concat(res.data.newsList.list);
       
       //如果列表数据条数>=总条数，不再触发滚动加载
        if (this.auctionList.length >= this.total) {
          this.finished = true;
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.home-product{
  padding-top: 10px;
}
.items{
  margin: 10px 5px;
  background: #fff;
  border-radius: 10px;
  img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}
.item-text{
  h5{
    display: table-cell;
    text-align: left;
    padding: 7px;
  }
}
.flex-start{
    display: flex;
    font-size: 12px;
    color: #888;
    strong{
      font-weight: normal;
      padding: 7px;
    }
}
</style>
